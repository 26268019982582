import {productImageRef, storage} from "@/config/firebase";
import {getDownloadURL, uploadBytesResumable, deleteObject, ref} from "firebase/storage";

class StorageService{
    /**
     * Upload file to storage and return file url
     * @param {File} file image file
     * @param {String} folder unique reference. Could be an identifier or key of some sort to include in the path
     * @param {progressListener} listener pass in a callback function with progress value as parameters
     */
    async uploadFile (file: File, folder: string, listener?: progressListener): Promise<string>{
        return new Promise((resolve, reject) => {
            const fileExtension = file.name.split(".").pop();
            const reference = productImageRef(`${folder}/${new Date().valueOf()}.${fileExtension}`);
            const uploadtask = uploadBytesResumable(reference, file);
            uploadtask
                .on('state_changed', snapshot => {
                        if(listener){
                            const progress = Number.parseInt(((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toString());
                            listener(progress);
                        }
                },
                    (error)=>reject(error),
                    ()=>{resolve(getDownloadURL(uploadtask.snapshot.ref))}
                )
        })
    }

    deleteFile(url: string): Promise<void>{
        return deleteObject(ref(storage, url));
    }
}
interface progressListener{
    (progress: number): void;
}
export default StorageService