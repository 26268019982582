class FuncUtils{

    static validateFields (itemObject: Record<string, any>, fieldsToExclude?: string[]): boolean{
        return Object
            .entries(itemObject)
            .every(value => {
                const excludedFields: any[] = [];
                if(fieldsToExclude) excludedFields.push(...fieldsToExclude)
                const keyPair=value[0], valuePair = value[1];
                if(excludedFields.includes(keyPair)) return true
                if(typeof valuePair === 'function') return true;
                if(typeof valuePair === 'boolean') return true;
                if(Array.isArray(valuePair) || typeof valuePair === 'string') return valuePair.length > 0;
                if(typeof valuePair === 'object') return Object.entries(valuePair).length > 0;
                if(typeof valuePair === 'number') return valuePair > 0
                return !!valuePair;
            })
    }
    static getReadableDate(iso_str: Date | string | number): string{
        const date = new Date(iso_str);
        let result = "";
        if(date.valueOf() === date.valueOf()){
            result = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`
        }
        return result
    }
}

export default FuncUtils