
import {defineComponent, onMounted, ref, computed, watch, toRefs} from "vue";
import * as toastr from "toastr";
import StorageService from "@/services/StorageService";
import imageCompression from 'browser-image-compression';

export default defineComponent({
  name: "itemImage",
  props: {
    imageFile: {
      required: true,
      type: Object
    },
    imageIndex: {
      required: true,
      type: Number
    },
    folder: {
      required: true,
      type: String
    }
  },
  emits: ["cancel", "upload"],
  setup(props, {emit}){
    let imageURL = ref("");
    const {imageIndex} = toRefs(props);
    const uploadProgress = ref(0);
    const imageObj = computed(()=>props.imageFile.file.name.split("."));
    const deleteImage = async ()=>{
      try{
        await new StorageService().deleteFile(props.imageFile.url)
      }catch (e){
        console.log("Error deleting file:"+ e.message)
      }finally {
        emit("cancel", imageIndex)
      }
    }
    async function handleImageUpload() {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }

      //image size in MB
      const imageSize = props.imageFile.file.size / 1024 / 1024

        try {
          if(imageSize > 1) {
            const compressedFile = await imageCompression(props.imageFile.file, options);
            imageURL.value = await new StorageService().uploadFile(compressedFile, props.folder, progress => uploadProgress.value = progress)
          }
          else {
            imageURL.value = await new StorageService().uploadFile(props.imageFile.file, props.folder, progress => uploadProgress.value = progress)
          }

        } catch (error) {
          toastr.error(error.message, "Error uploading file");
        }
    }

    watch(imageURL, (newURL)=>{
      if(newURL){
        emit("upload", newURL);
      }
    });
    onMounted(()=>{
      handleImageUpload()
    });
    return {
      uploadProgress,
      imageObj,
      deleteImage
    }
  }
})
